<template>
    <div class="h-100">
        <div class="row justify-content-center py-3 mx-1">
            <div class="col-lg-4">
                <div class="card shadow p-md-4">
                    <div class="card-body text-justify">
                        <h5 class="card-title">What is WhoFly ?</h5>
                        <p class="card-text">
                            Juste une app made in Marseille pour nos pilotes FPV.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
</style>
