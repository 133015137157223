<template>
    <div class="h-100">
        <transition
            mode="out-in"
            enter-active-class="animate__animated_custom animate__animated animate__faster animate__fadeInRight"
            leave-active-class="animate__animated_custom animate__animated animate__faster animate__fadeOutLeft">
            <router-view v-if="ready"/>
        </transition>
    </div>
</template>

<script>
import Api from '@/Api'

export default {
    data: function () {
        return {
            ready: false
        }
    },
    async mounted() {
        const profile = Api.getProfile()
        await Api.updateProfile(profile.id, profile)
        this.ready = true
    },
}
</script>

<style>
</style>