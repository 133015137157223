<template>
    <div class="h-100">
        <div class="row justify-content-center py-3 mx-1">
            <div class="col-lg-4">
                <div class="card shadow p-md-4">
                    <div class="card-body text-justify">
                        <h5 class="card-title">Contact</h5>
                        <p class="card-text mb-3">
                           If you have questions or ideas for improvement, you can contact me by email : <a href="mailto:contact@instalive.fr">contact@whofly.fr</a>
                        </p>

                        <div class="d-flex flex-row"><font-awesome-icon :icon="['fab', 'github']" size="lg" class="icon"></font-awesome-icon> <a href="https://github.com/omouren" target="_blank">omouren</a></div>
                        <div class="d-flex flex-row my-2"><font-awesome-icon :icon="['fab', 'instagram']" size="lg" class="icon"></font-awesome-icon> <a href="https://www.instagram.com/olivier.mouren/" target="_blank">@olivier.mouren</a></div>
                        <div class="d-flex flex-row"><font-awesome-icon :icon="['fab', 'youtube']" size="lg" class="icon"></font-awesome-icon> <a href="https://www.youtube.com/channel/UCJi-wllO8GY5f9k8gB_VGTg" target="_blank">Olivier Mouren</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped lang="scss">
    .icon {
        min-width: 30px;
        padding-right: 10px;
    }

    a {
        color: var(--text-color);
    }

    a:hover {
        color: var(--primary-color);
    }
</style>
