<template>
    <div class="h-100 vw-100 overflow-auto text-center">
        <img src="/Frequency-Reference-Chart-2020-12-23-011.png">
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped lang="scss">
    img {
        max-height: 100%;
    }
</style>
