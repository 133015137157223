import Vue from 'vue'
import Router from 'vue-router'
import Home from "./components/Home"
import Profile from "./components/Profile"
import CreateSession from "./components/Online/Create"
import JoinSession from "./components/Online/Join"
import OfflineSession from "./components/Offline/Session"
import Contact from "./components/pages/Contact"
import About from "./components/pages/About"
import Chart from "./components/pages/Chart"
import Online from "./components/Online"
import Session from "./components/Online/Session"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/online',
      name: 'online',
      component: Online,
      children: [
        {
          name: 'create-session',
          path: 'create-session',
          component: CreateSession,
        },
        {
          name: 'join-session',
          path: 'join-session',
          component: JoinSession,
        },
        {
          name: 'session',
          path: 'session/:sessionId',
          component: Session,
        },
      ]
    },
    {
      path: '/offline-session',
      name: 'offline-session',
      component: OfflineSession,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/chart',
      name: 'chart',
      component: Chart,
    }
  ]
})

export default router