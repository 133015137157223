<template>
    <div v-if="profile && users.length" class="h-100 d-flex flex-column">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <div class="current-session-block d-flex flex-row align-items-center justify-content-center my-3">
                <div class="session-block d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1" v-clipboard="() => sessionId"
                     v-clipboard:success="onClipBoardSuccess">
                    <div>Session</div>
                    <div class="session-id" v-tooltip="{ content: 'Copied !', show: showTooltip, trigger: 'manual' }">
                        {{ sessionId }}
                    </div>
                </div>

                <router-link class="channel-block d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1" :to="{name: 'profile'}">
                    <div>My channel</div>
                    <div v-if="currentFrequency" class="channel-id d-flex flex-column align-items-center justify-content-center">
                        <div>{{ currentFrequency.band.name }}</div>
                        <div>{{ currentFrequency.channel.name }}</div>
                    </div>
                    <div v-else>
                        Unknown
                    </div>
                </router-link>
            </div>
            <div>
                <div @click="updateFlying" class="flying-button" :class="profile.flying ? 'flying' : ''">
                    <font-awesome-icon icon="plane-departure" size="2x" v-if="profile.flying"/>
                    <font-awesome-icon icon="plane-arrival" size="2x" v-else/>
                    <div v-if="!profile.flying">Not flying</div>
                    <div v-else>Flying</div>
                </div>
            </div>
        </div>
        <div class="row mt-3 flex-grow-1">
            <div class="col d-flex flex-column">
                <ul class="list-group list-group-flush flex-grow-1">
                    <li class="list-group-item" v-for="pilot of pilots" :key="pilot.uuid">
                        <div class="d-flex align-items-center">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <div>{{ pilot.name }}</div>
                                <div v-if="pilot.frequency.channel"
                                     :class="pilot.flying ? 'text-success' : 'text-danger'">
                                    <font-awesome-icon v-if="pilot.conflict" icon="exclamation-triangle" class="text-warning mr-2"/>{{ pilot.frequency.band.name }} - {{ pilot.frequency.channel.name }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <button class="btn btn-primary mb-3" @click="leave">Leave this session</button>
            </div>
        </div>
    </div>
</template>

<script>
import {frequencies} from '@/config.json'
import Api from "@/Api"
import Swal from "sweetalert2"

export default {
    data: function () {
        return {
            showTooltip: false,
            profile: null,
            users: [],
            userListener: null,
            usersListener: null
        }
    },
    computed: {
        pilots() {
            return this.users.map(user => {
                const band = frequencies.find(frequency => frequency.band.key === user.frequencyBand)?.band || null
                const channel = band ? frequencies.find(frequency => frequency.band.key === user.frequencyBand).channels.find(channel => channel.name === user.frequencyChannel) : null

                return {
                    uuid: user.id,
                    name: user.name,
                    frequency: {
                        band,
                        channel
                    },
                    flying: user.flying,
                    conflict: !!this.conflictFrequencies.find(conflictFrequency => conflictFrequency.user.id === user.id)
                }
            })
        },
        sessionId() {
            return this.$route.params.sessionId
        },
        conflictFrequencies() {
            const conflictFrequencies = []
            if (this.users.length && this.profile.frequencyBand && this.profile.frequencyChannel) {
                const currentFrequencyChannel = frequencies.find(f => f.band.key === this.profile.frequencyBand).channels.find(c => c.name === this.profile.frequencyChannel)
                const channelMin = currentFrequencyChannel.freq - (currentFrequencyChannel.range / 2)
                const channelMax = currentFrequencyChannel.freq + (currentFrequencyChannel.range / 2)

                for (const pilot of this.users) {
                    if (pilot.flying && pilot.frequencyBand && pilot.frequencyChannel && pilot.id !== this.profile.id) {
                        const pilotChannel = frequencies.find(f => f.band.key === pilot.frequencyBand).channels.find(c => c.name === pilot.frequencyChannel)

                        const pilotMin = pilotChannel.freq - (pilotChannel.range / 2)
                        const pilotMax = pilotChannel.freq + (pilotChannel.range / 2)

                        if (Math.max(channelMin, pilotMin) <= Math.min(channelMax, pilotMax)) {
                            const band = frequencies.find(frequency => frequency.band.key === pilot.frequencyBand)?.band || null
                            const channel = band ? frequencies.find(frequency => frequency.band.key === pilot.frequencyBand).channels.find(channel => channel.name === pilot.frequencyChannel) : null

                            conflictFrequencies.push({
                                user: pilot,
                                band,
                                channel
                            })
                        }
                    }
                }
            }

            return conflictFrequencies
        },
        currentFrequency() {
            if (this.profile.frequencyBand && this.profile.frequencyChannel) {
                const band = frequencies.find(frequency => frequency.band.key === this.profile.frequencyBand)?.band || null
                const channel = band ? frequencies.find(frequency => frequency.band.key === this.profile.frequencyBand).channels.find(channel => channel.name === this.profile.frequencyChannel) : null

                return {
                    band,
                    channel
                }
            }

            return null
        }
    },
    methods: {
        async updateFlying() {
            if (!this.profile.flying && this.conflictFrequencies.length) {
                const result = await Swal.fire({
                    icon: 'warning',
                    title: 'Be careful',
                    html: 'You can interfere with :<br>' + this.conflictFrequencies.map(conflictFrequency => `${conflictFrequency.user.name} (${conflictFrequency.band.name} - ${conflictFrequency.channel.name})`).join('<br>'),
                    showCancelButton: true,
                })

                if (result.isConfirmed) {
                    await Api.updateProfile(this.profile.id, {flying: !this.profile.flying})
                }
            } else {
                await Api.updateProfile(this.profile.id, {flying: !this.profile.flying})
            }
        },
        updateUsers(users) {
            this.users = users
        },
        updateProfile(user) {
            this.profile = user
        },
        onClipBoardSuccess() {
            this.showTooltip = true
            setTimeout(() => {
                this.showTooltip = false
            }, 5000)
        },
        async leave() {
            await Api.updateProfile(this.profile.id, {flying: false, sessionId: null})
            await this.$router.push({name: 'home'})
        },
    },
    mounted() {
        const profile = Api.getProfile()
        this.usersListener = Api.listenToSessionUsers(this.sessionId, this.updateUsers)
        this.userListener = Api.listenToUser(profile.id, this.updateProfile)
    },
    beforeDestroy() {
        if (this.usersListener) {
            this.usersListener()
        }
        if (this.userListener) {
            this.userListener()
        }
    }
}
</script>

<style scoped lang="scss">
.current-session-block {
    display: flex;
    background-color: rgba(150, 150, 150, .2);
    border-radius: 10px;
    padding: 10px 0;
    width: 100%;
    max-width: 300px;

    .session-block {
        border-right: 1px solid hsla(0, 0.0%, 100%, 0.1);
        padding: 0 15px;

        .session-id {
            font-size: 28px;
        }
    }

    .channel-block {
        padding: 0 15px;
        user-select: none;
        color: white !important;

        .channel-id {
            font-size: 20px;
        }

        &:hover {
            text-decoration: none !important;
        }
    }
}

.flying-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #dc3545;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    user-select: none;

    &.flying {
        background-color: var(--primary-color);
    }
}

.text-success {
    color: var(--primary-color) !important;
}

ul {
    padding: 0 !important;
}
</style>