<template>
    <div>
    </div>
</template>

<script>
import Api from '@/Api'

export default {
    async mounted() {
        const profile = Api.getProfile()
        const sessionId = Math.random().toString(36).substr(2, 5).toUpperCase()
        await Api.joinSession(profile.id, sessionId)
        await this.$router.push({name: 'session', params: {sessionId}})
    }
}
</script>

<style>
</style>