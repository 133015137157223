import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faUser, faQuestionCircle, faGlobe, faSlash, faChartBar, faPlaneDeparture, faPlaneArrival, faExclamationTriangle, faPlus, faHome } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faGithub, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'
import Clipboard from 'v-clipboard'
import VTooltip from 'v-tooltip'
import './registerServiceWorker'
import 'animate.css/animate.min.css'
import Breakpoints from "./plugins/Breakpoints";
import JQuery from 'jquery'
import vuetify from './plugins/vuetify'

library.add(faBars, faUser, faQuestionCircle, faGlobe, faSlash, faChartBar, faEnvelope, faGithub, faYoutube, faInstagram, faPlaneDeparture, faPlaneArrival, faExclamationTriangle, faPlus, faHome)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.use(Clipboard)
Vue.use(VTooltip)
Vue.use(Breakpoints, {
  breakpointRanges: {
    xs: [0, 575],
    sm: [576, 767],
    md: [768, 991],
    lg: [992, 1199],
    xl: [1200, 9999999],
  }
})

window.$ = JQuery
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
