<template>
    <div class="row justify-content-center my-3">
        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-column">
                        <v-text-field
                            label="Name"
                            v-model="form.name"
                            required
                        ></v-text-field>

                        <v-select
                            label="Band"
                            v-model="form.frequencyBand"
                            :items="availableBands"
                            item-text="name"
                            item-value="key"
                            @change="form.frequencyChannel = null"
                        ></v-select>

                        <v-select
                            label="Channel"
                            v-model="form.frequencyChannel"
                            :items="availableChannels"
                            :disabled="!form.frequencyBand"
                            :item-text="item => item.name + (currentSessionUsers.length && !item.available ? ' (Used)' : '')"
                            :color="!selectedChannel ? 'primary' : (!currentSessionUsers.length || selectedChannel.available ? 'success' : 'error')"
                            item-value="name"
                        ></v-select>

                        <button type="button" class="btn btn-primary btn-block" @click="submit">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {frequencies} from '@/config.json'
import Api from "@/Api"
import Swal from "sweetalert2";

export default {
    data: function () {
        return {
            profile: null,
            form: {
                name: null,
                frequencyBand: null,
                frequencyChannel: null
            },
            currentSessionUsers: [],
            usersListener: null
        }
    },
    computed: {
        availableFrequencies() {
            if (this.currentSessionUsers.length) {
                return frequencies.map(band => {
                    band.channels = band.channels.map(channel => {
                        channel.available = true
                        const channelMin = channel.freq - (channel.range / 2)
                        const channelMax = channel.freq + (channel.range / 2)

                        for (const pilot of this.currentSessionUsers) {
                            if (pilot.flying && pilot.frequencyBand && pilot.frequencyChannel && pilot.id !== this.profile.id) {
                                const pilotChannel = frequencies.find(f => f.band.key === pilot.frequencyBand).channels.find(c => c.name === pilot.frequencyChannel)

                                const pilotMin = pilotChannel.freq - (pilotChannel.range / 2)
                                const pilotMax = pilotChannel.freq + (pilotChannel.range / 2)

                                if (Math.max(channelMin, pilotMin) <= Math.min(channelMax, pilotMax)) {
                                    channel.available = false
                                }
                            }
                        }

                        return channel
                    })

                    return band
                })
            }

            return frequencies
        },
        availableBands() {
            return this.availableFrequencies.map(frequency => frequency.band)
        },
        availableChannels() {
            let frequency = this.availableFrequencies.find(frequency => frequency.band.key === this.form.frequencyBand)
            return frequency ? frequency.channels : []
        },
        selectedChannel() {
            return this.availableChannels.find(frequency => frequency.name === this.form.frequencyChannel)
        }
    },
    mounted() {
        this.profile = Api.getProfile()
        this.form = {
            name: this.profile.name,
            frequencyBand: this.profile.frequencyBand,
            frequencyChannel: this.profile.frequencyChannel
        }

        if (this.profile.sessionId) {
            this.usersListener = Api.listenToSessionUsers(this.profile.sessionId, this.updateCurrentSessionUsers)
        }
    },
    methods: {
        updateCurrentSessionUsers(users) {
            this.currentSessionUsers = users
        },
        async submit() {
            await Api.updateProfile(this.profile.id, this.form)
            await Swal.fire({
                icon: 'success',
                title: 'Profile updated'
            })
        }
    },
    beforeDestroy() {
        if (this.usersListener) {
            this.usersListener()
        }
    }
}
</script>

<style>
</style>