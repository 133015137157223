import {initializeApp} from 'firebase/app';
import {getDatabase, ref, set, get, onValue, query, orderByChild, equalTo} from "firebase/database";
import {defaultProfile} from '@/config.json'
import {v4 as uuidv4} from "uuid"

const firebaseConfig = {
    apiKey: "AIzaSyDQFcmoeCE-jF4FvPw5m7hMPggtYw5m3XM",
    authDomain: "whofly-e2a37.firebaseapp.com",
    databaseURL: "https://whofly-e2a37-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "whofly-e2a37",
    storageBucket: "whofly-e2a37.appspot.com",
    messagingSenderId: "1080503733090",
    appId: "1:1080503733090:web:94222dfe965c58dbe4fa3b"
};

initializeApp(firebaseConfig);

const db = getDatabase();

export default class {
    static getRandomName() {
        const pilots = ['Olivier Mouren', 'J-True', 'Blackbird', 'YKS', 'AirFlex', 'Stéphane Couchoud', 'DML', 'DronePassion13', 'Mr Steele', 'Johnny FPV']
        const random = Math.floor(Math.random() * pilots.length)
        return pilots[random]
    }

    static getProfile() {
        if (localStorage.profile) {
            return JSON.parse(localStorage.profile)
        } else {
            const profile = defaultProfile
            profile.name = this.getRandomName()
            profile.id = uuidv4()
            return profile
        }
    }

    static async updateProfile(userId, changes) {
        let existingUser = (await get(ref(db, 'users/' + userId))).val()

        if (existingUser) {
            existingUser = {...existingUser, ...changes}
        } else {
            existingUser = {id: userId, ...changes}
        }

        localStorage.profile = JSON.stringify(existingUser)

        return set(ref(db, 'users/' + userId), existingUser);
    }

    static async joinSession(userId, sessionId) {
        await this.updateProfile(userId, {sessionId})
    }

    static listenToSessionUsers(sessionId, callback) {
        const usersRef = query(ref(db, 'users'), orderByChild('sessionId'), equalTo(sessionId));
        return onValue(usersRef, (snapshot) => {
            if (snapshot.val()) {
                const data = Object.values(snapshot.val());
                callback(data)
            }
        });
    }

    static listenToUser(userId, callback) {
        const userRef = ref(db, 'users/' + userId)
        return onValue(userRef, (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                callback(data)
            }
        });
    }
}