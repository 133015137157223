<template>
    <div class="row flex-column justify-content-center align-items-center h-100">
        <div class="col-md-4 text-center align-items-center d-flex flex-column justify-content-center">
            <div class="form-row">
                <div class="col">
                    <v-text-field
                        label="Session ID"
                        v-model="form.sessionId"
                        required autofocus
                        @keydown.enter="joinSession"
                    ></v-text-field>
                    <button class="btn btn-primary btn-block" @click="joinSession">JOIN</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/Api"

export default {
    data: function () {
        return {
            form: {
                sessionId: null
            }
        }
    },
    methods: {
        async joinSession() {
            const profile = Api.getProfile()
            await Api.joinSession(profile.id, this.form.sessionId)
            await this.$router.push({name: 'session', params: {sessionId: this.form.sessionId}})
        }
    }
}
</script>

<style>
</style>