<template>
    <div>
        <div class="row mt-4">
            <div class="col">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="pilot of pilots" :key="pilot.uuid">
                        <div class="d-flex align-items-center">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <div>{{ pilot.name }}</div>
                                <div>{{ pilot.frequency.band.name }} - {{ pilot.frequency.channel.name }}</div>
                            </div>
                            <button class="btn btn-sm btn-warning ml-2" @click="editPilotModal(pilot)">Edit</button>
                            <button class="btn btn-sm btn-danger ml-2" @click="removePilot(pilot)">X</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <v-btn
            elevation="2"
            fab
            fixed
            bottom
            right
            color="success"
            @click="addPilotModal"
        >
            <font-awesome-icon icon="plus"/>
        </v-btn>

        <pilot-modal modal-id="pilotModal" :title="modalTitle" :bands="bands" :available-channels="availableChannels"
                     v-model="form" @submit="submit" @cancel="cancel"></pilot-modal>
    </div>
</template>

<script>
import {frequencies} from '@/config.json'
import {v4 as uuidv4} from 'uuid'
import PilotModal from "./PilotModal"

export default {
    props: ['socket', 'userId'],
    components: {PilotModal},
    data() {
        return {
            pilots: [],
            modalType: null,
            form: {
                name: null,
                band: null,
                channel: null
            }
        }
    },
    mounted() {
        if (localStorage.pilots) {
            this.pilots = JSON.parse(localStorage.pilots)
        }
    },
    computed: {
        modalTitle() {
            return this.modalType === 'add' ? 'Add a pilot' : 'Edit a pilot'
        },
        bands() {
            return frequencies.map(frequency => frequency.band)
        },
        channels() {
            if (this.form.band) {
                return frequencies.find(frequency => frequency.band.key === this.form.band).channels
            } else {
                return []
            }
        },
        availableChannels() {
            return this.channels.filter(channel => {
                let channelMin = channel.freq - (channel.range / 2)
                let channelMax = channel.freq + (channel.range / 2)

                for (let pilot of this.pilots) {
                    let pilotMin = pilot.frequency.channel.freq - (pilot.frequency.channel.range / 2)
                    let pilotMax = pilot.frequency.channel.freq + (pilot.frequency.channel.range / 2)

                    if (Math.max(channelMin, pilotMin) <= Math.min(channelMax, pilotMax)) {
                        return false
                    }
                }

                return true
            })
        }
    },
    methods: {
        addPilotModal() {
            this.modalType = 'add'

            this.form = {
                id: null,
                name: null,
                band: null,
                channel: null
            }

            window.$('#pilotModal').modal('show')
        },
        editPilotModal(pilot) {
            this.modalType = 'edit'

            this.form = {
                id: pilot.uuid,
                name: pilot.name,
                band: pilot.frequency.band.key,
                channel: pilot.frequency.channel.name
            }

            this.removePilot(pilot)

            window.$('#pilotModal').modal('show')
        },
        submit() {
            if (this.modalType === 'add') {
                this.addPilot()
            }

            if (this.modalType === 'edit') {
                this.updatePilot()
            }

            window.$('#pilotModal').modal('hide')
        },
        cancel() {
            if (this.modalType === 'edit') {
                this.cancelUpdatePilot()
            }
        },
        addPilot() {
            this.pilots.push({
                uuid: uuidv4(),
                name: this.form.name,
                frequency: {
                    band: frequencies.find(frequency => frequency.band.key === this.form.band).band,
                    channel: frequencies.find(frequency => frequency.band.key === this.form.band).channels.find(channel => channel.name === this.form.channel)
                }
            })

            localStorage.pilots = JSON.stringify(this.pilots)
        },
        updatePilot() {
            this.pilots.map(pilot => {
                if (pilot.uuid !== this.form.id) {
                    return pilot
                }

                return {
                    uuid: this.form.id,
                    name: this.form.name,
                    frequency: {
                        band: frequencies.find(frequency => frequency.band.key === this.form.band).band,
                        channel: frequencies.find(frequency => frequency.band.key === this.form.band).channels.find(channel => channel.name === this.form.channel)
                    }
                }
            })

            localStorage.pilots = JSON.stringify(this.pilots)
        },
        cancelUpdatePilot() {
            this.pilots.push({
                uuid: this.form.id,
                name: this.form.name,
                frequency: {
                    band: frequencies.find(frequency => frequency.band.key === this.form.band).band,
                    channel: frequencies.find(frequency => frequency.band.key === this.form.band).channels.find(channel => channel.name === this.form.channel)
                }
            })

            localStorage.pilots = JSON.stringify(this.pilots)
        },
        removePilot(pilotToRemove) {
            this.pilots = this.pilots.filter(pilot => pilot.uuid !== pilotToRemove.uuid)

            localStorage.pilots = JSON.stringify(this.pilots)
        }
    }
}
</script>

<style>
</style>