<template>
    <div class="modal fade" :id="modalId" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="col">
                            <v-text-field
                                label="Pilot name"
                                v-model="value.name"
                                :light="true"
                                required
                            ></v-text-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-6">
                            <v-select
                                label="Band"
                                v-model="value.band"
                                :items="bands"
                                item-text="name"
                                item-value="key"
                                @change="value.channel = null"
                                :light="true"
                            ></v-select>
                        </div>

                        <div class="form-group col-6">
                            <v-select
                                label="Channel"
                                v-model="value.channel"
                                :items="availableChannels"
                                item-text="name"
                                item-value="name"
                                :disabled="!value.band"
                                :light="true"
                            ></v-select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="submit">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['modalId', 'title', 'bands', 'availableChannels', 'value'],
    mounted() {
        window.$(`#${this.modalId}`).on('hide.bs.modal', () => {
            this.$emit('cancel')
        })
    },
    methods: {
        submit() {
            this.$emit('submit')
        },
    }
}
</script>

<style scoped lang="scss">
    .modal-title {
        color: black;
    }
</style>
