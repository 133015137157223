<template>
    <div class="row flex-column justify-content-center align-items-center h-100">
        <div class="my-session-block d-flex flex-column mb-10" v-if="profile && profile.sessionId">
            <div class="current-session-block d-flex flex-row">
                <div v-clipboard="() => profile.sessionId"
                     v-clipboard:success="onClipBoardSuccess"
                     class="session-block d-flex flex-column justify-content-center align-items-center h-100 w-50">
                    <div>Session</div>
                    <div class="session-id" v-tooltip="{ content: 'Copied !', show: showTooltip, trigger: 'manual' }">
                        {{ profile.sessionId }}
                    </div>
                </div>

                <div class="session-list-block w-50">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" v-for="pilot of pilots" :key="pilot.uuid">
                            <div class="d-flex align-items-center">
                                <div class="d-flex justify-content-between">
                                    <div :class="pilot.flying ? 'text-success' : 'text-danger'">{{ pilot.name }}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="btn-group">
                <button class="btn btn-success btn-my-session w-50" @click="leave">Leave</button>

                <router-link class="btn btn-success btn-my-session w-50"
                             :to="{name: 'session', params: { sessionId: profile.sessionId }}"
                             v-if="profile && profile.sessionId">
                    See
                </router-link>
            </div>
        </div>

        <div class="col-md-4 text-center align-items-center d-flex flex-column justify-content-center">
            <router-link class="btn btn-primary btn-block" :to="{name: 'create-session'}">
                Create a session
            </router-link>

            <router-link class="btn btn-primary btn-block" :to="{name: 'join-session'}">
                Join a session
            </router-link>

            <router-link class="btn btn-outline-primary btn-block" :to="{name: 'offline-session'}">
                Offline session
            </router-link>
        </div>
    </div>
</template>

<script>
import Api from "@/Api";

export default {
    data: function () {
        return {
            showTooltip: false,
            profile: null,
            usersListener: null,
            userListener: null,
            currentSessionUsers: []
        }
    },
    computed: {
        pilots() {
            return this.currentSessionUsers.map(user => {
                return {
                    uuid: user.id,
                    name: user.name,
                    flying: user.flying
                }
            })
        }
    },
    methods: {
        onClipBoardSuccess() {
            this.showTooltip = true
            setTimeout(() => {
                this.showTooltip = false
            }, 5000)
        },
        updateProfile(user) {
            this.profile = user
        },
        updateCurrentSessionUsers(users) {
            this.currentSessionUsers = users
        },
        async leave() {
            await Api.updateProfile(this.profile.id, {flying: false, sessionId: null})
        }
    },
    mounted() {
        this.profile = Api.getProfile()
        if (this.profile.sessionId) {
            this.usersListener = Api.listenToSessionUsers(this.profile.sessionId, this.updateCurrentSessionUsers)
        }
        this.userListener = Api.listenToUser(this.profile.id, this.updateProfile)
    },
    beforeDestroy() {
        if (this.userListener) {
            this.userListener()
        }
        if (this.usersListener) {
            this.usersListener()
        }
    }
}
</script>

<style scoped lang="scss">
.my-session-block {
    user-select: none;
    width: 100%;
    max-width: 450px;

    .current-session-block {
        display: flex;
        background-color: rgba(150, 150, 150, .2);
        border-radius: 10px 10px 0 0;
        padding: 10px 0;

        .session-block {
            border-right: 1px solid hsla(0, 0.0%, 100%, 0.1);
            padding: 0 15px;

            .session-id {
                font-size: 28px;
            }
        }

        .session-list-block {
            padding: 0 15px;
            max-height: 200px;
            overflow: auto;

            ul {
                padding: 0 !important;

                .list-group-item {
                    padding: 0.75rem 0;
                }
            }
        }
    }

    .btn-my-session {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
</style>