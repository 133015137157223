<template>
    <v-app class="app d-flex flex-column h-100">
        <nav class="header navbar fixed-top navbar-expand-sm shadow-xl justify-content-between">
            <a href="/"><img src="/logo.png" class="logo" alt="WhoFly"></a>

            <div class="navbar-toggler" @click="showSideMenu = true">
                <font-awesome-icon icon="bars"/>
            </div>

            <div class="user d-none d-sm-block" @click="showSideMenu = true">
                <font-awesome-icon icon="bars"/>
            </div>
        </nav>

        <transition
            enter-active-class="animate__animated_custom animate__animated animate__faster animate__slideInRight"
            leave-active-class="animate__animated_custom animate__animated animate__faster animate__slideOutRight">
            <div class="sidemenu-container" v-show="showSideMenu" @click="showSideMenu = false">
                <div class="sidemenu shadow-xl">
                    <ul class="navbar-nav sidemenu-nav">
                        <li class="nav-item" :class="currentRouteName === 'home' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'home'}">
                                <font-awesome-icon icon="home" class="nav-icon"/>
                                <span>Home</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'profile' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'profile'}">
                                <font-awesome-icon icon="user" class="nav-icon"/>
                                <span>Profile</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'session' ? 'active' : null"
                            v-if="currentSessionId">
                            <router-link class="btn btn-block"
                                         :to="{name: 'session', params: { sessionId: currentSessionId }}">
                                <font-awesome-icon icon="globe" class="nav-icon"/>
                                <span>Online session</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'offline-session' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'offline-session'}">
                                <font-awesome-layers>
                                    <font-awesome-icon icon="globe"/>
                                    <font-awesome-icon icon="slash"/>
                                </font-awesome-layers>
                                <span>Offline session</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'about' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'about'}">
                                <font-awesome-icon icon="question-circle" class="nav-icon"/>
                                <span>About</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'contact' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'contact'}">
                                <font-awesome-icon :icon="['far', 'envelope']" class="nav-icon"/>
                                <span>Contact</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'chart' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'chart'}">
                                <font-awesome-icon icon="chart-bar" class="nav-icon"/>
                                <span>Chart</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>

        <main role="main" class="container-fluid flex-grow-1" style="overflow-x: hidden;">
            <transition
                mode="out-in"
                enter-active-class="animate__animated_custom animate__animated animate__faster animate__fadeInRight"
                leave-active-class="animate__animated_custom animate__animated animate__faster animate__fadeOutLeft">
                <router-view class="mx-2"/>
            </transition>
        </main>
    </v-app>
</template>

<script>
import Api from "@/Api"

export default {
    name: 'App',
    components: {},
    data: function () {
        return {
            userListener: null,
            showSideMenu: false,
            currentSessionId: null
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name
        },
    },
    methods: {
        updateCurrentSessionId(user) {
            this.currentSessionId = user.sessionId
        }
    },
    async mounted() {
        const profile = Api.getProfile()
        await Api.updateProfile(profile.id, profile)
        this.userListener = Api.listenToUser(profile.id, this.updateCurrentSessionId)
    },
    beforeDestroy() {
        if (this.userListener) {
            this.userListener()
        }
    }
}
</script>

<style lang="scss">
:root {
    --bg-color: #0e0e10;
    --card-bg-color: #1e2023;
    --primary-color: #0ba54d;
    --primary-light-color: #51d984;
    --text-color: #efeff1;
    --live-thumb-width: calc(1080px / 4);
    --live-thumb-height: calc(1920px / 4);
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    height: 100vh;
    color: var(--text-color) !important;
    overflow-x: hidden;
}

.container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.btn {
    color: #fff !important;
}

.btn-primary {
    transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
    background-size: 200% auto;
    background-image: linear-gradient(to right, var(--primary-color) 0%, var(--primary-light-color) 50%, var(--primary-color) 100%);
    background-position: left center;
    border: 0 !important;
}

.btn-success {
    background-color: var(--primary-color) !important;
    border: 0 !important;

    &:hover {
        background-color: var(--primary-light-color) !important;
    }
}

.btn-primary-revert {
    background-position: right center;
}

.btn-primary:hover {
    background-position: right center;
}

.btn-outline-primary {
    position: relative;
    color: #fff !important;
    border-color: var(--primary-color) !important;
    backdrop-filter: blur(10px);
    background-color: inherit !important;
}

.btn-outline-primary::after {
    transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
    content: "";
    background-image: linear-gradient(to right, var(--primary-color) 0%, var(--primary-light-color) 50%, var(--primary-color) 100%);
    background-size: 200% auto;
    background-position: left center;
    border-radius: 3px;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.btn-outline-primary:hover {
    background-color: inherit !important;
    color: #fff !important;
}

.btn-outline-primary:hover::after {
    background-position: right center;
    opacity: 1;
}

.card {
    background-color: #1e2023 !important;
    border-radius: 20px;
}

.list-group-item {
    background: none !important;
    border-color: hsla(0, 0.0%, 100%, 0.1) !important;
}

hr {
    border-color: hsla(0, 0.0%, 100%, 0.1) !important;
}

.shadow-xl {
    box-shadow: 0 0 2rem rgba(0, 0, 0, .7) !important;
}

.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            background: $color;
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, .1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}
</style>

<style scoped lang="scss">
.app {
    .logo {
        max-height: 30px;
    }

    .header {
        background-color: #18181b;
        min-height: 54px;

        .navbar-collapse {
            margin-top: 5px;
        }

        .navbar-toggler {
            padding: 0;
        }

        .profile-pic-block {
            margin: 2px;
            width: 32px;
            height: 32px;
            position: relative;

            .profile-pic {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                border-radius: 100%;
                z-index: 2;
            }

            .profile-pic-border {
                position: absolute;
                border-radius: 100%;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: -2px;
                background-image: linear-gradient(to right top, var(--primary-color) 0%, #f7be50 100%);
                z-index: 1;
            }
        }

        .nav-item a {
            color: #efeff1;
        }

        .nav-item.active {
            position: relative;

            a {
                position: relative;
                z-index: 2;
                background-color: #18181b;
                border-radius: 0;
            }

            .nav-item-border {
                position: absolute;
                transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin-bottom: -4px;
                background-size: 200% auto;
                background-image: linear-gradient(to right, var(--primary-color) 0%, #f7be50 50%, var(--primary-color) 100%);
                background-position: left center;
                z-index: 1;
            }
        }

        .nav-item.active:hover {
            .nav-item-border {
                background-position: right center;
            }
        }
    }

    .sidemenu-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2000;
        display: flex;
        justify-content: flex-end;

        .sidemenu {
            width: 84%;
            max-width: 400px;
            height: 100%;
            overflow-y: auto;
            background-color: #18181b;

            .sidemenu-user {
                background-color: var(--bg-color);
                position: relative;

                .sidemenu-user-border {
                    position: absolute;
                    bottom: 0;
                    transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
                    height: 3px;
                    width: 100%;
                    margin-bottom: -4px;
                    background-size: 200% auto;
                    background-image: linear-gradient(to right, var(--primary-color) 0%, #f7be50 50%, var(--primary-color) 100%);
                    background-position: left center;
                }
            }

            .profile-pic-block {
                position: relative;
                width: 64px;
                height: 64px;
                margin-bottom: 8px;

                .profile-pic {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    border-radius: 100%;
                    z-index: 2;
                }

                .profile-pic-border {
                    position: absolute;
                    border-radius: 100%;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: -3px;
                    background-image: linear-gradient(to right top, var(--primary-color) 0%, var(--primary-light-color) 100%);
                    z-index: 1;
                }
            }

            .profile-username {
                color: #ff7652;
                font-weight: bold;
            }

            .sidemenu-nav {
                .nav-item.active {
                    span {
                        color: var(--primary-color);
                    }
                }

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: var(--text-color);
                    line-height: 2.5;

                    svg, .fa-layers {
                        margin-left: 10px;
                        min-width: 20px;
                    }

                    .fa-layers {
                        svg {
                            margin-left: 0;
                        }
                    }

                    span {
                        margin-left: 15px;
                    }
                }
            }
        }

        .sidemenu:hover {
            .sidemenu-user {
                .sidemenu-user-border {
                    background-position: right center;
                }
            }
        }
    }

    main {
        margin-top: 54px;
        background-color: var(--bg-color);
    }

    .footer {
        width: 100%;
        background-color: #1f1f23;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        .logo {
            filter: grayscale(100%);
        }
    }
}
</style>
